import React from 'react';
import { connect } from 'react-redux';

import Button from 'common/components/Button';

import * as actions from '../actions';

const DeleteExpectationButton = (props) => {
  const { expectation, targetCname, deleteDomainExpectation } = props;
  const deleteButtonProps = {
    onClick: () => deleteDomainExpectation(targetCname, expectation.key),
    className: 'delete-expectation',
    variant: 'error'
  };

  return <Button {...deleteButtonProps}>Remove</Button>;
};

const mapStateToProps = state => ({
  targetCname: state.targetCname
});

const mapDispatchToProps = {
  deleteDomainExpectation: actions.deleteDomainExpectation,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteExpectationButton);
