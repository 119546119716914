import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../actions';
import AddExpectation from './AddExpectation';
import ExpectationList from './ExpectationList';

class DomainConfigContracts extends React.Component {
  componentDidMount() {
    const { targetCname } = window.socrata;
    this.props.fetchData(targetCname);
  }

  render() {
    const { domainExpectations } = this.props;

    return (
      <div className="domain-config-contracts">
        <AddExpectation expectations={domainExpectations} />
        <ExpectationList expectations={domainExpectations} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  domainExpectations: state.domainExpectations
});

const mapDispatchToProps = {
  fetchData: actions.fetchData,
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainConfigContracts);
