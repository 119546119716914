import React from 'react';

import ExpectationDetails from './ExpectationDetails';
import ExpectationProvisions from './ExpectationProvisions';

const ExpectationContent = ({ expectation }) => {
  const provisionsInfo = expectation.provisions ?
    <ExpectationProvisions provisions={expectation.provisions} /> :
    <h4>NO PROVISIONS FOUND</h4>;

  return (
    <div className="expectation-content">
      <ExpectationDetails expectation={expectation} />
      {provisionsInfo}
    </div>
  );
};

export default ExpectationContent;
