import React from 'react';

const ExpectationTitle = ({ expectation }) => {
  const provisions = expectation.provisions || [];
  return (
    <span className="title-container">
        <div>{expectation.name}</div>
        <div>{correctProvisionCount(provisions)} out of {provisions.length} Correctly Set</div>
      </span>
  );
};

const correctProvisionCount = (provisions) =>
  provisions.filter((provision) => provision.desired_setting === provision.current_setting).length;

export default ExpectationTitle;
