import * as actions from './actions';

// Shape of the store
const initialState = {
  contracts: [],
  domainExpectations: [], // Array of expectation.contractKey
  targetCname: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CONTRACTS:
      return { ...state, contracts: action.contracts };
    case actions.SET_DOMAIN_EXPECTATIONS:
      return { ...state, domainExpectations: action.domainExpectations };
    case actions.SET_TARGET_CNAME:
      return { ...state, targetCname: action.targetCname };
    default:
      return state;
  }
};
