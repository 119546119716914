export const SET_TARGET_CNAME = 'SET_TARGET_CNAME';
export const setTargetCname = (targetCname) => ({ type: SET_TARGET_CNAME, targetCname });

export const SET_CONTRACTS = 'SET_CONTRACTS';
export const setContracts = (contracts) => ({ type: SET_CONTRACTS, contracts });

export const SET_DOMAIN_EXPECTATIONS = 'SET_DOMAIN_EXPECTATIONS';
export const setDomainExpectations = (domainExpectations) =>
  ({ type: SET_DOMAIN_EXPECTATIONS, domainExpectations });

// Handled by sagas.js
export const FETCH_DATA = 'FETCH_DATA';
export const fetchData = (targetCname) => ({ type: FETCH_DATA, targetCname });

export const ADD_DOMAIN_EXPECTATION = 'ADD_DOMAIN_EXPECTATION';
export const addDomainExpectation = (targetCname, contractKey) =>
  ({ type: ADD_DOMAIN_EXPECTATION, targetCname, contractKey });

export const DELETE_DOMAIN_EXPECTATION = 'DELETE_DOMAIN_EXPECTATION';
export const deleteDomainExpectation = (targetCname, contractKey) =>
  ({ type: DELETE_DOMAIN_EXPECTATION, targetCname, contractKey });

export const ENFORCE_DOMAIN_EXPECTATION = 'ENFORCE_DOMAIN_EXPECTATION';
export const enforceDomainExpectation = (targetCname, contractKey) =>
  ({ type: ENFORCE_DOMAIN_EXPECTATION, targetCname, contractKey });
