import React from 'react';
import get from 'lodash/get';

import utcToString from 'common/utcToString';

const ExpectationDetails = ({ expectation }) => {
  return (
    <div className="expectation-details">
      <div className="expectation-details-row">
        <div className="field-name">Description:</div>
        <div>{expectation.description}</div>
      </div>
      <div className="expectation-details-row">
        <div className="field-name">Created At:</div>
        <div>{utcToString(get(expectation, 'created.at'))}</div>
      </div>
      <div className="expectation-details-row">
        <div className="field-name">Created By:</div>
        <div>{get(expectation, 'created.by.email')}</div>
      </div>
      <div className="expectation-details-row">
        <div className="field-name">Key:</div>
        <div>{expectation.key}</div>
      </div>
    </div>
  );
};

export default ExpectationDetails;
