import React from 'react';

import ExpectationContent from './ExpectationContent';
import ExpandableSection from './ExpandableSection';
import DeleteExpectationButton from './DeleteExpectationButton';
import EnforceExpectationButton from './EnforceExpectationButton';
import ExpectationTitle from './ExpectationTitle';

const Expectation = ({ expectation }) => (
  <div className="expectation">
    <ExpandableSection title={<ExpectationTitle expectation={expectation} />}>
      <ExpectationContent expectation={expectation}/>
    </ExpandableSection>
    <EnforceExpectationButton expectation={expectation}/>
    <DeleteExpectationButton expectation={expectation}/>
  </div>
);

export default Expectation;
