import { all, call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import sortBy from 'lodash/sortBy';

import { getAllContracts } from 'common/core/contracts';

import {
  showErrorToastNow,
  showSuccessToastNow
} from 'common/components/ToastNotification/Toastmaster';

import {
  fetchDomainExpectations,
  addDomainExpectation,
  deleteDomainExpectation
} from 'common/core/domains';

import {
  auditDomainExpectation,
  enforceDomainExpectation
} from 'common/frontend/internal';

import * as actions from './actions';

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_DATA, handleData);
  yield takeEvery(actions.ADD_DOMAIN_EXPECTATION, handleAddDomainExpectation);
  yield takeEvery(actions.DELETE_DOMAIN_EXPECTATION, handleDeleteDomainExpectation);
  yield takeEvery(actions.ENFORCE_DOMAIN_EXPECTATION, handleEnforceDomainExpectation);
}

// Handlers
function* handleData({ targetCname }) {
  try {
    yield put(actions.setTargetCname(targetCname));
    yield refreshDomainExpectations({ targetCname });
  } catch (err) {
    console.error(err);
    yield call(showErrorToastNow, 'Failed to fetch data');
  }
}

function* handleAddDomainExpectation({ targetCname, contractKey }) {
  try {
    yield call(addDomainExpectation, targetCname, contractKey);
    yield call(showSuccessToastNow, 'Successfully added expectation!');
  } catch (err) {
    console.error(err);
    yield call(showErrorToastNow, 'Failed to add expectation');
  } finally {
    yield refreshDomainExpectations({ targetCname });
  }
}

function* handleDeleteDomainExpectation({ targetCname, contractKey }) {
  try {
    yield call(deleteDomainExpectation, targetCname, contractKey);
    yield call(showSuccessToastNow, 'Successfully deleted expectation!');
  } catch (err) {
    console.error(err);
    yield call(showErrorToastNow, 'Failed to delete expectation');
  } finally {
    yield refreshDomainExpectations({ targetCname });
  }
}

function* handleEnforceDomainExpectation({ targetCname, contractKey }) {
  try {
    yield call(enforceDomainExpectation, targetCname, contractKey);
    yield call(showSuccessToastNow, 'Successfully enforced expectation!');
  } catch (err) {
    console.error(err);
    yield call(showErrorToastNow, 'Failed to enforce expectation');
  } finally {
    yield refreshDomainExpectations({ targetCname });
  }
}

/**
 * Helpers
 */

function* refreshDomainExpectations({ targetCname }) {
  try {
    const contracts = yield getContracts();
    const expectations = yield call(fetchDomainExpectations, targetCname);
    const annotatedExpectations = yield all(
      expectations.map((expectation) => annotateExpectation(expectation, contracts, targetCname))
    );
    yield put(actions.setDomainExpectations(annotatedExpectations));
  } catch (err) {
    console.error(err);
  }
}

// Tries to save API calls by checking the store and fetching if list is empty
function* getContracts(refresh = false) {
  const existingContracts = yield select((state) => state.contracts);
  if (refresh || existingContracts.length === 0) {
    const contracts = yield call(getAllContracts);
    yield put(actions.setContracts(sortBy(contracts, ['name', 'created.at'])));
    return contracts;
  }

  return existingContracts;
}

// Combines contract info and audit info into one expectation object
function* annotateExpectation(expectation, contracts, targetCname) {
  const matchingContract = contracts.find((contract) => contract.key === expectation.contractKey);
  const audit = yield call(auditDomainExpectation, targetCname, expectation.contractKey);

  const annotatedProvisions = (matchingContract.provisions || []).map((provision) => ({
    ...provision,
    ...audit[provision.lookup]
    })
  );

  return {
    ...matchingContract,
    provisions: annotatedProvisions
  };
}
