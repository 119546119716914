import React from 'react';
import { connect } from 'react-redux';

import CachedAutocomplete from 'common/components/CachedAutocomplete';

import * as actions from '../actions';

const AddExpectation = (props) => {
  const {
    addDomainExpectation,
    contracts,
    domainExpectations,
    targetCname
  } = props;

  const addExpectation = (matchedObjects) => addDomainExpectation(targetCname, matchedObjects[0].key);

  const expectationKeys = domainExpectations.map((expectation) => expectation.key);
  const availableContracts = contracts.filter((contract) => !expectationKeys.includes(contract.key));

  const autocompleteProps = {
    dataCached: availableContracts,
    searchKeys: ['name'],
    onQuerySubmit: addExpectation,
    focusFirstResult: true,
    placeholder: 'Add a Contract',
    onSelectSetSelectionAsQuery: false,
    className: 'add-expectation-autocomplete',
  };

  return (
    <section className="add-expectation">
      <h6>Add New Contracts</h6>
      <div className="add-expectation-inputs">
        <CachedAutocomplete {...autocompleteProps} />
      </div>
    </section>
  );
};

const mapStateToProps = state => ({
  contracts: state.contracts,
  domainExpectations: state.domainExpectations,
  targetCname: state.targetCname
});

const mapDispatchToProps = {
  addDomainExpectation: actions.addDomainExpectation,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddExpectation);
