import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import DomainConfigContracts from './components/DomainConfigContracts';
import './domain-config-contracts.scss';
import domainContractsSaga from './sagas';
import domainContractsReducer from './reducer';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  domainContractsReducer,
  applyMiddleware(sagaMiddleware)
);

sagaMiddleware.run(domainContractsSaga);

ReactDOM.render(
  <Provider store={store}>
    <DomainConfigContracts />
  </Provider>,
  document.querySelector('#domain-config-contracts')
);
