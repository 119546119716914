import React from 'react';

import Expectation from './Expectation';

const ExpectationList = ({ expectations }) => (
  <div className="expectations-table-container">
    {expectations.map((expectation) => <Expectation expectation={expectation} key={expectation.key} />)}
  </div>
);

export default ExpectationList;
