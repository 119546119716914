import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';

import { AccordionPane } from 'common/components/Accordion';

class ExpandableSection extends React.Component {
  state = {
    isExpanded: get(this, 'props.isExpanded', false)
  };

  toggleExpanded = () => this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));

  render() {
    const { className, children, title } = this.props;
    const classes = classNames('expandable-section', className);

    return (
      <AccordionPane title={title}
                     onToggle={this.toggleExpanded}
                     isOpen={this.state.isExpanded}
                     className={classes}>
        {children}
      </AccordionPane>
    );
  }
}

export default ExpandableSection;
