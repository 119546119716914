import React from 'react';
import { connect } from 'react-redux';

import Button from 'common/components/Button';

import * as actions from '../actions';

const EnforceExpectationButton = (props) => {
  const { expectation, targetCname, enforceDomainExpectation } = props;
  const enforceButtonProps = {
    onClick: () => enforceDomainExpectation(targetCname, expectation.key),
    className: 'enforce-domain-expectation',
    variant: 'success'
  };

  return <Button {...enforceButtonProps}>Enforce</Button>;
};

const mapStateToProps = state => ({
  targetCname: state.targetCname
});

const mapDispatchToProps = {
  enforceDomainExpectation: actions.enforceDomainExpectation,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnforceExpectationButton);
