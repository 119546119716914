import React from 'react';
import toString from 'lodash/toString';

import ResultsTable from 'common/components/ResultsTable';

import HighlightDiff from './HighlightDiff';

const ExpectationProvisions = ({ provisions }) => (
  <div className="expectation-provisions">
    <h4 className="expectation-provisions-title">Provisions:</h4>
    <ResultsTable data={provisions}
                  noResultsMessage={''}
                  rowKey='lookup'>
      <ResultsTable.Column dataIndex='type'
                           header='Type' />
      <ResultsTable.Column dataIndex='lookup'
                           header='Name' />
      <ResultsTable.Column dataIndex='desired_setting'
                           dataClassName='setting'
                           header='Expected Value'>
        {toString}
      </ResultsTable.Column>
      <ResultsTable.Column dataIndex='current_setting'
                           dataClassName='setting'
                           header='Current Value'
                           cellComponent={HighlightDiff} />
    </ResultsTable>
  </div>
);

export default ExpectationProvisions;
